import React from "react"
import Seo from "../components/seo"
import {MainContainer} from "../components/styles"
import styled from "styled-components"
import { highlight } from "../components/styles"


const InfoLinkExternal = styled.a`
  font-size: 2rem;
  text-decoration: none;
  margin-top: 20%;
  grid-column-start: 2;
  text-align: center;
  color: ${highlight};
  justify-self:center;
  align-self: center;
`

export default () => {
  return <>
    <Seo title="demo"/>
    <MainContainer>
      <InfoLinkExternal target="_blank" href={'circleapp://app/login'}>app link login</InfoLinkExternal>
      <InfoLinkExternal target="_blank" href={'circleapp://app/password-reset'}>app link reset</InfoLinkExternal>
      <InfoLinkExternal target="_blank" href={'https://circleapp.sc0ville.com/app/login'}>http link login</InfoLinkExternal>
    </MainContainer>
  </>
}

